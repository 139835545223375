<template>
    <div class="practical-content">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item v-if="role === 3">
                <span class="color-blue" @click="goBackThree">班级管理</span>
            </el-breadcrumb-item>
            <el-breadcrumb-item v-if="role === 4">
                <span class="color-blue" @click="goBackTwo">我的班级</span>
            </el-breadcrumb-item>
            <el-breadcrumb-item v-if="role === 3">
                <span class="color-blue" @click="goBackTwo">{{className}}</span>
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                <span class="color-blue" @click="goBack">实训列表</span>
            </el-breadcrumb-item>
            <el-breadcrumb-item>实训详情</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="practical-details">
<!--            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="实训详情" name="first">
                    <TrainingDetailsModule></TrainingDetailsModule>
                </el-tab-pane>
                <el-tab-pane label="实训提交" name="second">

                </el-tab-pane>
            </el-tabs>-->
            <div class="practical-details">
                <project-info :id="id" :tabs="tabs" :project="project" :app_lists="app_lists">
                    <template v-if="role === 4" v-slot:extender>
                        <task-commit :ids="ids"></task-commit>
                    </template>
                    <template v-slot:extend>
                        <TrainingSubmissionModule ref="getSubmitList"></TrainingSubmissionModule>
                    </template>
                </project-info>
            </div>
        </div>
    </div>
</template>

<script>
    import TrainingDetailsModule from '../../components/practical/TrainingDetailsModule';
    import TrainingSubmissionModule from '../../components/practical/TrainingSubmissionModule';
    import ProjectInfo from "@/components/material/ProjectInfo";
    import TaskCommit from "@/components/material/TaskCommit";

    export default {
        name: "practicalDetail",
        components: {
            TrainingDetailsModule,
            TrainingSubmissionModule,
            ProjectInfo,
            TaskCommit,
        },
        created() {
            this.projectInfo();
        },
        data(){
            return{
                //实训id
                id: this.$route.query.p_id ? Number(this.$route.query.p_id) : 0,
                ids: {
                    project_id: this.$route.query.p_id ? Number(this.$route.query.p_id) : 0,
                    sx_id: this.$route.query.sx_id ? Number(this.$route.query.sx_id) : 0
                },
                //角色id
                role: Number(localStorage.getItem('role')) || '',
                //班级名称
                className: this.$route.query.className || '',
                //实训名称
                practicalName: '',
                //实训详情数据
                practicalDetailData: {},
                activeName: 'first',
                project: {
                    project_title: '',
                    project_ability: [],
                    project_attrs: [],
                    project_evaluate: [],
                    project_knowledge_link: [],
                    project_sucai: [],
                    project_task: [],
                    project_duration: 0,
                    project_difficulty: 0
                },
                app_lists: [],
                tabs: [
                    '实训详情',
                    '实训提交'
                ]
            }
        },
        methods: {
            projectInfo() {
                this.$shttp.axiosGetBy(this.$api.getProjectDetail, {id: this.ids.project_id}, (res) => {
                    if (res.code === 200)
                    {
                        this.project = Object.assign({}, res.data);
                        this.app_lists = res.data.app_lists;
                    }
                }, (err) => {
                    this.$common.axiosErrorMsg(err);
                })
            },
            handleClick() {
                this.$refs.getSubmitList.getStudentPractical();
            },
            goBack() {
                this.$router.go(-1)
            },
            goBackTwo() {
                this.$router.go(-2)
            },
            goBackThree() {
                this.$router.go(-3)
            },
            goBackFour() {
                this.$router.go(-4)
            }
        }
    }
</script>

<style scoped lang="scss">
    .practical-content{
        width: 1200px;
        margin: 0 auto;
        height: calc(100% - 30px);
        display: flex;
        flex-direction: column;
        .practical-details{
            height: calc(100% - 55px);
            display: flex;
            flex-direction: column;
            flex: 1;
            background-color: #fff;
           ::v-deep .el-tabs {
                height: 100%;
                display: flex;
                flex-direction: column;
                .el-tabs__content {
                    flex: 1;
                    height: calc(100% - 80px);
                    .el-tab-pane {
                        height: 100%;
                    }
                }
            }
            ::v-deep.el-tabs__item.is-active{
                color: #fff;
                background: #409EFF;
            }
            ::v-deep.el-tabs__active-bar{
                background-color: transparent;
            }
            ::v-deep.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
                padding-left: 20px;
            }
            ::v-deep.el-tabs--top .el-tabs__item.is-top:last-child {
                padding-right: 20px;
            }
            ::v-deep.el-tabs--card>.el-tabs__header{
                padding: 20px;
            }
            ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__nav{
                border: 1px solid #409eff;
                height: 40px;
                border-radius: 4px;
            }
            ::v-deep.el-tabs__header{
                margin: 0;
            }
        }

        ::v-deep .el-breadcrumb {
            padding: 20px 0;
            .el-breadcrumb__inner {
                color: #000;
            }
        }
    }
</style>