<template>
    <div class="training-submission">
        <el-table :data="submissionList" border style="width: 100%; flex: 1; height: 1%"  @current-change="handleCurrentChange" size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa', textAlign: 'center'}" :cell-style="{fontSize: '12px',color: '#333', textAlign: 'center'}">
            <el-table-column prop="rank" label="序号" width="320" ></el-table-column>
            <el-table-column prop="s_name" label="姓名"></el-table-column>
            <el-table-column prop="update_time" label="提交时间" align="center"></el-table-column>
            <el-table-column prop="create_time" label="评分" align="center">
                <template slot-scope="scope">
                    <div class="pf-act-content" v-if="role === 3">
                        <template v-if="scope.row.teacher_score">
                            <span>{{scope.row.teacher_score}}分</span>
                        </template>
                        <template v-else>
                            <el-input-number v-model="teacher_score" controls-position="right" class="pw-input"  :min="0" :max="100"></el-input-number>
                            <el-tooltip content="确定之后，则无法修改分数" placement="top-start">
                                <el-button type="primary" size="small" class="pw-confirm-btn" @click="submitContent(scope.row)">确定</el-button>
                            </el-tooltip>
                        </template>
                    </div>
                    <div v-if="role === 4">
                        <span v-if="scope.row.teacher_score">{{scope.row.teacher_score}}</span>
                        <span v-else >暂无评分</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <div @click="clickViewDetail(scope.row)" class="detail-title">查看详情</div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination v-if="role === 3"
            class="pages-center" background
            :current-page.sync="practicalPages.currentPageNum"
            :page-size="practicalPages.eachPageNum"
            :total="practicalPages.total"
            layout="prev, pager, next, jumper"
            @current-change="practicalCurrentChange">
        </el-pagination>
        <!--S 查看详情弹窗-->
        <el-dialog
                title="查看详情"
                :visible.sync="viewDetail"
                width="600px"
                class="submit-detail">
                <div class="comment-content" style="display: flex;height: 300px;">
                    <el-scrollbar style="height: 100%;width: 100%;">
                        <div class="comment-txt">
                            <p class="detail-text" v-html="submit_content"></p>
                        </div>
                    </el-scrollbar>
                </div>
                <div class="teacher-comments" v-if="role === 4">
                    <span class="text">教师评语</span>
                    <span class="comments" v-if="teacher_comment">{{teacher_comment}}</span>
                    <span class="comments" v-else >暂无评语</span>
                </div>
                <div class="teacher-comments" v-if="role === 3">
                    <span class="text">教师评语</span>
                    <el-input
                        class="textarea"
                        type="textarea"
                        :rows="6"
                        style="resize:none"
                        placeholder="请输入内容"
                        v-model="teacher_comment">
                    </el-input>
                </div>
                <div class="comment-btns">
                    <el-button type="primary" @click="viewDetail = false" v-if="role === 4">确定</el-button>
                    <el-button type="primary" @click="submitContent" v-if="role === 3">确定</el-button>
                </div>
        </el-dialog>
        <!--E 查看详情弹窗-->
    </div>
</template>

<script>
    export default {
        name: "TrainingSubmissionModule",
        data(){
            return{
                role: Number(localStorage.getItem('role')),
                submissionList:[],
                submit_content: '',
                //分页
                practicalPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                viewDetail: false,
                //教师评语
                teacher_comment: '',
                //分数
                teacher_score: 0,
                //学生提交的id
                student_submit_id: '',
                currentRow: null
            }
        },
        created() {
          this.getStudentPractical();
        },
        methods:{
            //查看学生提交的实训列表
            getStudentPractical() {
                let params = {
                    page: this.practicalPages.currentPageNum,
                    limit: this.practicalPages.eachPageNum,
                    sx_id: this.$route.query.sx_id
                }
                this.$shttp.axiosGetBy(this.$api.drill_showStuPutDrillList, params, (res) => {
                    if (res.code === 200) {
                        this.submissionList = res.data.data;
                        this.submit_content = res.data.data.content;
                        this.practicalPages.total = res.data.total;
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //查看详情
            clickViewDetail(row) {
                this.viewDetail = true;
                this.teacher_comment = row.teacher_comment;
                this.student_submit_id = row.id;
                if (this.role === 3) {
                    this.submit_content = row.content;
                } else {
                    this.submit_content = row.content;
                }
            },
            handleCurrentChange(val) {
                if (val) {
                    this.currentRow = val;
                    this.student_submit_id = val.id;
                }
            },
            //提交评语/分数
            submitContent() {
                let formData = new FormData();
                formData.append('id', this.student_submit_id);
                if (this.teacher_comment) {
                    formData.append('teacher_comment', this.teacher_comment);
                }
                if (this.teacher_score) {
                    formData.append('teacher_score', this.teacher_score);
                }
                this.$shttp.axiosPost(this.$api.drill_teaCommentStuDrill, formData, (res) => {
                    if (res.code === 200) {
                        this.viewDetail = false;
                        this.$message.success(res.msg);
                        this.getStudentPractical();
                        this.teacher_score = 0;
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //分页
            practicalCurrentChange(val) {
                this.practicalPages.currentPageNum = val;
                this.getStudentPractical();
            },
        }
    }
</script>

<style scoped lang="scss">
    ul,li{
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .training-submission{
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
        .detail-title{
            cursor: pointer;
            &.detail-title:hover{
                color: #409eff;
            }
        }
        .detail-text{
            margin-bottom: 10px;
           ::v-deep img {
                max-width: 100%;
                max-height: 100%;
            }
        }

    }
    .pages-center {
        margin-bottom: 20px;
    }
    .teacher-comments {
        display: flex;
        flex-direction: column;
        height: 200px;
        padding: 0 20px;
        margin-top: 10px;
        .comments {
            padding: 20px;
        }
        .textarea {
            padding-top: 20px;
        }
    }
    .pw-input {
        width: 120px;
        margin-right: 5px;
    }
    ::v-deep.el-dialog__body{
        padding: 30px 0;
    }
    .comment-content {
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .comment-txt {
            width: 100%;
            padding: 0 20px;
            box-sizing: border-box;
        }
    }
    .comment-btns {
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>